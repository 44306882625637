export const SnapLogo = ({ color, size }: { color: string; size: number }) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 25 25`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
      <title>node-icon</title>
      <defs>
        <linearGradient x1="18.2919102%" y1="10.0218476%" x2="75.2362057%" y2="77.3326874%" id="linearGradient-1">
          <stop stop-color="#6239FF" offset="0%"></stop>
          <stop stop-color="#C326D6" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="Final-Designs" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icons">
          <g id="node-icon">
            <polygon id="Path" points="0 0 25 0 25 25 0 25"></polygon>
            <path d="M20.1635307,8.81670631 L20.1635307,11.1649473 L6.39264373,7.6223935 L11.0366915,4.59938041 L20.1635307,8.81670631 Z M11.8116251,18.6587023 L11.8116251,10.9999256 L19.1635161,12.8911326 L11.8116251,18.6587023 Z M9.89076494,17.9185993 L4.8364693,12.2747253 L4.8364693,9.20558518 L9.89076494,10.5058018 L9.89076494,17.9185993 Z M2.91551501,7.5936818 L2.91551501,13.0091801 L10.8424874,21.8606694 L22.084485,13.0410925 L22.084485,7.58822187 L10.8784476,2.41013641 L2.91551501,7.5936818 Z" fill="url(#linearGradient-1)"></path>
          </g>
        </g>
      </g>
  </svg>
);
